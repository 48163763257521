import React, { Component } from 'react'
import SeoHead from 'Components/SeoHead'
import Layout from 'Components/Layout'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import WindowStore from 'WindowStore'
import Img from 'Components/UI/Img'
import Video from 'Components/UI/Video'
import ScrollingMessageWithIcon from 'Components/UI/ScrollingMessageWithIcon'
import Link from 'Components/Utils/Link'
import isRetina from 'is-retina'
import LazyLoad from 'react-lazyload'
import styles from '../styles/pages/home.module.scss'

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
    this.parent = React.createRef()
    this.state = {
      viewMQ: undefined,
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize, 0)
  }
  componentDidUpdate() {
    setTimeout(this.resize, 0)
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    const viewMQ = WindowStore.getViewMediaQuery()
    if (viewMQ !== this.state.viewMQ) {
      this.setState({
        viewMQ
      })
    }
    this.video.current.resize()
  }
  videoDidLoad = () => {
  }
  getMQ = () => {
    let mq = 'md'
    if (WindowStore.Size.w <= AppConstants.MQ_MEDIUM) mq = 'sm'
    else if (WindowStore.Size.w > AppConstants.MQ_XXLARGE) mq = 'lg'
    if (mq === 'md' && isRetina()) mq = 'lg'
    return mq
  }
  render() {
    this.isMobile = WindowStore.Size.w < AppConstants.MQ_LARGE
    const videoMQ = this.getMQ()
    const backgroundColor = '#000000'
    const categories = [
      { id: 'heels', to: '/category/heels', name: 'Heels', src: 'heels.jpg', srcRetina: 'heels@2x.jpg' },
      { id: 'flats', to: '/category/flats', name: 'Flats', src: 'flats.jpg', srcRetina: 'flats@2x.jpg' },
      { id: 'sandals', to: '/category/sandals', name: 'Sandals', src: 'sandals.jpg', srcRetina: 'sandals@2x.jpg' },
    ].map((item) => {
      return (
        <Link key={`home-categories-push-${item.id}`} to={`${item.to}`} className={`${styles.categoryItem} u-col-4 u-col-lg-3`}>
          <Img
            src={`./images/fw19/home/cat-push/${item.src}`}
            srcRetina={`./images/fw19/home/cat-push/${item.srcRetina}`}
            noRetinaOnMobile={true}
          />
          <div className={`${styles.categoryTitle} ${styles.subtitle} t-color-black`}>{item.name}</div>
        </Link>
      )
    })
    const slidesData = [
      {
        src: `${AppConstants.S3_URL}editorial-page/fw19/ecom/home/videos_0/hybrids-chelseas__${videoMQ}.mp4`,
        preview: `${AppConstants.BASE_URL}images/fw19/stella-stellar/videos_0/hybrids-chelseas__preview.jpg`,
        title: `Stellar Chelsea`,
        subtitle: `Fall Winter 19 Stellar Collection`,
        to: `/edit/stella-stellar-collection/hybrids/chelseas`
      },
      {
        src: `${AppConstants.S3_URL}editorial-page/fw19/ecom/home/videos_0/hybrids-heels__${videoMQ}.mp4`,
        preview: `${AppConstants.BASE_URL}images/fw19/stella-stellar/videos_0/hybrids-heels__preview.jpg`,
        title: `Stellar Heels`,
        subtitle: `Fall Winter 19 Stellar Collection`,
        to: `/edit/stella-stellar-collection/hybrids/heels`
      },
      {
        src: `${AppConstants.S3_URL}editorial-page/fw19/ecom/home/videos_0/sneakers__${videoMQ}.mp4`,
        preview: `${AppConstants.BASE_URL}images/fw19/stella-stellar/videos_0/sneakers__preview.jpg`,
        title: `Lucien Lace-up`,
        subtitle: `Fall Winter 19 Stellar Collection`,
        to: `/edit/stella-stellar-collection/sneakers`
      },
      {
        src: `${AppConstants.S3_URL}editorial-page/fw19/ecom/home/videos_0/zip-chain__${videoMQ}.mp4`,
        preview: `${AppConstants.BASE_URL}images/fw19/stella-stellar/videos_0/zip-chain__preview.jpg`,
        title: `Lucien Chain, Zip and OTK`,
        subtitle: `Fall Winter 19 Stellar Collection`,
        to: `/edit/stella-stellar-collection/sneakers/zip-chain`
      },
      {
        src: `${AppConstants.S3_URL}editorial-page/fw19/ecom/home/videos_0/hybrids-booties__${videoMQ}.mp4`,
        preview: `${AppConstants.BASE_URL}images/fw19/stella-stellar/videos_0/hybrids-booties__preview.jpg`,
        title: `Stellar Bootie`,
        subtitle: `Fall Winter 19 Stellar Collection`,
        to: `/edit/stella-stellar-collection/hybrids/pull-on-booties`
      }
    ]
    const randomNum = Math.floor(Math.random() * slidesData.length)
    const currentSlide = slidesData[randomNum]
    const slide = (
      <div className={styles.slide}>
        <Link to={currentSlide.to} className={`${styles.slideInside} `}>
          <div className={`${styles.slideText}`}>
            <p className={`${styles.paragraph} t-color-white ${styles.slideSub}`}>{currentSlide.subtitle}</p>
            <h1 className={`${styles.title} t-color-white u-uppercase`}>{currentSlide.title}</h1>
          </div>
          <div className={`${styles.slideWrapper}`}>
            <Video
              ref={this.video}
              soundControl={false}
              autoplay
              loop
              muted
              full={true}
              src={currentSlide.src}
              preview={{
                src: currentSlide.preview,
                srcRetina: currentSlide.preview,
                description: `A homepage preview video picture of ${currentSlide.title} video from ${currentSlide.subtitle}.`
              }}
              didLoad={this.videoDidLoad}
            />
          </div>
        </Link>
      </div>
    )
    return (
      <Layout>
        <SeoHead
          title='Official Site – For the modern urban woman of today'
          path={this.props.location.pathname}
        />
        <ScrollingMessageWithIcon/>
        <Link to={`/red`}>
          <Img
            className={`u-col-12`}
            src={`/images/RED/home-red.gif`}
            srcRetina={`/images/RED/home-red@2x.gif`}
            noRetinaOnMobile={true}
          />
        </Link>
        <div className='u-height--lg--40' />
        <div className={`u-height--only-lg--20`}></div>
        <div ref={this.parent} className={`${styles.parent} u-user-select--none`} style={{backgroundColor: backgroundColor}}>{slide}</div>
        <div className={`u-height--only-lg--20`}></div>
        <div className={`${styles.categoriesContainer}`}>
          <LazyLoad height={400} offset={100} once>
            <div className={`u-row u-offset-lg-1`}>{ categories }</div>
          </LazyLoad>
        </div>
        {/* <div className={`${styles.eventContainer}`}>
          <Link to={`/edit/saint-germain-popup-store-stellar-with-refinery29`}>
            <div className={`u-row`}>
              <LazyLoad height={500} offset={100} once>
                <Img
                  className={`u-col-12 u-offset-0 u-col-lg-5 u-offset-lg-1 ${styles.mainImg}`}
                  src={`./images/fw19/home/event/stellar_space.jpg`}
                  srcRetina={`/images/fw19/home/event/stellar_space@2x.jpg`}
                  noRetinaOnMobile={true}
                />
              </LazyLoad>
              <LazyLoad height={500} offset={100} once>
                <Img
                  className={`u-col-12 u-col-lg-5 ${styles.mainImg}`}
                  src={`./images/fw19/home/event/influencers.jpg`}
                  srcRetina={`/images/fw19/home/event/influencers@2x.jpg`}
                  noRetinaOnMobile={true}
                />
              </LazyLoad>
            </div>
            <div className='u-height--lg--60' />
            <div className={`u-col-lg-10 u-offset-lg-1`}>
              <h2 className={`${styles.title} u-pad-x--lg t-color-white u-text-center`}>A Stellar Evening</h2>
              <div className='u-height--only-lg--10' />
              <p className={`u-col-lg-8 u-offset-lg-2 ${styles.paragraph} u-pad-x--lg t-color-white u-text-center`}>To mark the Paris Fashion Week opening of our new Stellar space, Stella Luna partnered with Refinery29 for a Stellar Evening, filled with adventure, cocktails and star-chart readings by Alice Bell.</p>
            </div>
          </Link>
          <div className='u-height--only-lg--60' />
          <div className='u-height--lg--0' />
        </div> */}
      </Layout>
    )
  }
}

export default IndexPage
